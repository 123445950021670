import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Social() {
    return (
        <Container>
            <Row></Row>
        </Container>
    )
}

export default Social
