import BlindsightImage from '../../../../img/projects/blindsight.jpg'
import BobafetchImage from '../../../../img/projects/bobafetch.jpg'
import CheaterImage from '../../../../img/projects/cheater.jpg'
import LoafImage from '../../../../img/projects/loaf.jpg'
import MicrowaveImage from '../../../../img/projects/microwave.jpg'
import PictRNNaryImage from '../../../../img/projects/pictrnnary.jpg'
import SaasImage from '../../../../img/projects/saas.jpg'
import UntitledImage from '../../../../img/projects/untitled.jpg'

export default [
    {
        href:
            'https://devpost.com/software/blindsight-virtual-eyes-through-haptic-feedback',
        title: 'Blindsight',
        description:
            'Blindsight simulates a novel sense of sight with haptic feedback vibration motors and ML.',
        img: BlindsightImage,
    },
    {
        href: 'https://devpost.com/software/bobafetch',
        title: 'BobaFetch',
        description:
            "BobaFetch is an automated boba tea barista system. Leveraging the most advanced tools known to mankind (cardboard, tape, free cups) and some other stuff that's also kinda cool (Azure, CockroachDB, Domain.com), our team has successfully produced BobaFetch v0.1. This earliest version is a Minimum Viable Product that is capable of dispensing a customer-ready cup of boba tea within 90 seconds.",
        img: BobafetchImage,
    },
    {
        href: 'https://devpost.com/software/cheaterti',
        title: 'CheaterTI',
        description:
            'There is a major problem plaguing academic institutions nationwide: poor grades. This is especially the case for students in STEM. This will be a problem no more. Introducing: CheaterTI; the solution to all things regarding academic dishonesty.',
        img: CheaterImage,
    },
    {
        href: 'https://github.com/DevinMui/LoafTriangle',
        title: 'Loaf',
        description:
            'In almost every household in the United States, WiFi signals are present. In this experiment/project, we will develop software to sniff for active probes emitted by connection attempts from WiFi-enabled devices such as smartphones. In every WiFi request, there is a present Received Signal Strength Indicator (RSSI) which shows a -dBm value. As the RSSI value is the strength of the WiFi signal between the wireless access point (WiFi AP), we can correlate this value with distance as well as obstructions. However, WiFi signals fluctuate because of WiFi signal reflections. In this experiment, we will attempt to trilaterate the internal position of a WiFi-enabled device.',
        img: LoafImage,
    },
    {
        href: 'https://devpost.com/software/muicrowave',
        title: 'Muicrowave',
        description:
            "After jokingly talking about creating a smart microwave at a previous competition, we decided to make this joke a reality. Our team came prepared with the plan of making the muicrowave. Some features directly inspired by Brian Anderson. Our microwave is unlike any other. Using the Google Cloud Vision API, users no longer have to deal with the guesswork involved in normal microwaves. Simply scan your food item, pop it in the oven, and ask Alexa to turn the microwave on. It's that easy.",
        img: MicrowaveImage,
    },
    {
        href:
            'https://www.hackerearth.com/challenges/hackathon/cal-hacks-50/dashboard/61b39b3/submission/',
        title: 'PictRNNary',
        description:
            'In an increasingly digitized world, nobody wants to play pen-and-paper games anymore. Yet this screen revolution means that the analog beauty of pencil drawings and the camaraderie shared by friends are all lost.<br/><br/>How can we take a classic drawing game and make it appealing to users of the digital age? Answer: PictRNNary!',
        img: PictRNNaryImage,
    },
    {
        href:
            'https://devpost.com/software/robotic-arm-meal-maker-and-automated-drone-delivery',
        title: 'SaaS (Sandwich as a Service)',
        description:
            'We were inspired by robotic arms, that are used in factories to make the vehicles. They were able to work with such efficiency, we knew we wanted to build something similar. With this in mind, we knew that building a robotic arm to make a car was illogical. So, we set our sights on a robotic arm to make sandwiches.',
        img: SaasImage,
    },
    {
        href: 'https://devpost.com/software/untitled-princess-carolyn-project',
        title: 'Untitled Princess Carolyn Project',
        description:
            'Everyone has a daily routine. For me, that would be waking up, drinking tea, skipping class, and coding until I pass out from exhaustion. However, I find one part of this process to be excruciatingly mundane. That would, of course, be the drinking tea part. In fact, there are over 10 steps to drinking tea. This is a daunting number of tasks and no one should have to go through this ordeal.',
        img: UntitledImage,
    },
]
