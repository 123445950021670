import React from 'react'

function Footer() {
    return (
        <footer>
            <p>
                Designed and Coded with{' '}
                <span role="img" aria-label="hearts">
                    ❤️💛❤️
                </span>{' '}
                by Devin Mui &copy; 2020
            </p>
        </footer>
    )
}

export default Footer
